export const environment = {
  production: false,
  type: 'test',
  firebase: {
    apiKey: 'AIzaSyBDT9JpBM3BU9Twz2uLSiAIXmFx8wRE0xo',
    authDomain: 'cagehuntertest.firebaseapp.com',
    databaseURL: 'https://cagehuntertest.firebaseio.com',
    projectId: 'cagehuntertest',
    storageBucket: 'cagehuntertest.appspot.com',
    messagingSenderId: '26039122253'
  },
  algolia: {
    apiKey: 'e9bbfb42b5df672ba0a8ae217c737b8f',
    aplicationID: 'BMCYFUSYZV'
  },
};
